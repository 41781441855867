import { Injectable } from '@angular/core';
import { PlanType } from '@app/+authenticated/+reports/shared/subscriptions/subscription.model';
import { getAccountSubscription } from '@app/reducers/account/account.service';
import { hasAtleastSubscriptionPlan } from '@app/shared/subscription-plan/subscription-plan.directive';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';

import { AppState } from '../../index';
import { WeatherAction } from './weather.action';
import { WeatherApi } from './weather.api';
import { WeatherLoadRequest } from './weather.model';

@Injectable()
export class WeatherService {
  public constructor(
    private store: Store<AppState>,
    private api: WeatherApi,
  ) {}

  public load(weatherLoadRequest: WeatherLoadRequest, updateStore = true) {
    return this.store.select(getAccountSubscription).pipe(
      map((subscription) => hasAtleastSubscriptionPlan(PlanType.BASIC, subscription)),
      switchMap((hasBasic) =>
        hasBasic ? this.api.load(weatherLoadRequest, WeatherAction.load(weatherLoadRequest)) : of(undefined),
      ),
      first(),
      tap((response) => {
        if (updateStore) {
          this.store.dispatch(WeatherAction.loadSuccess(response, weatherLoadRequest));
        }
      }),
      catchError((error) => {
        this.store.dispatch(WeatherAction.loadFailed(error));
        return of(error);
      }),
    );
  }
}
